import React from 'react'
import html from '../assets/experience/html.png'
import css from '../assets/experience/css.png'
import js from '../assets/experience/javascript.png'
import react from '../assets/experience/react.png'
import mongo from '../assets/experience/mongodb.jpg'
import sql from '../assets/experience/sql.png'
import node from '../assets/experience/node.png'
import tailwind from '../assets/experience/tailwind.png'

const Experience = () => {
    const techs = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 3,
            src: js,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 4,
            src: react,
            title: 'React',
            style: 'shadow-blue-600'
        },
        {
            id: 5,
            src: mongo,
            title: 'MongoDB',
            style: 'shadow-green-400'
        },
        {
            id: 6,
            src: sql,
            title: 'SQL',
            style: 'shadow-blue-500'
        },
        {
            id: 7,
            src: node,
            title: 'Node',
            style: 'shadow-lime-500'
        },
        {
            id: 8,
            src: tailwind,
            title: 'Tailwind',
            style: 'shadow-sky-400'
        },
    ]
  return (
    <div name='experience' className="bg-gradient-to-b from-gray-800 to-black w-full h-screen">
       <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full text-white">
        <div>
            <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">Experience</p>
            <p className="py-6">Here are some of my current tools!</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">

            {
                techs.map(({id, src, title, style}) => (
                    <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                    <img src={src} alt="currenttool" className="w-20 mx-auto"/>
                    <p className="mt-4">{title}</p>
                    </div>
                ))
            }
        
        </div>
       </div>
    </div>
  )
}

export default Experience