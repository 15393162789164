import React from 'react'
import GameCushy from '../assets/portfolio/GameCushy.jpg'
import Sme from '../assets/portfolio/SME.png'
//to add project just add id:2 src: img and import the image above
const Portfolio = () => {
    const portfolios = [
        {
            id: 1,
            src: GameCushy,
            Demo: 'https://www.gamecushy.com/',
            Code: 'https://github.com/BITshap/Game-Cushy',
        },
        {
            id: 2,
            src: Sme,
            Demo: 'https://www.smelivenation.com/',
            Code: 'https://http.cat/404',

        },
    ]
  return (
    <div name="portfolio" className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen">

        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
            <div className="pb-8">
            <p className="hidden md:flex text-4xl font-bold border-b-4 border-gray-500">Portfolio</p>
            <p className="py-6">Current Projects</p>
        </div>

         <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
         {
            portfolios.map(({id, src, Demo, Code}) => (
                <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
            <img src={src} alt="" className="rounded-md duration-200 hover:scale-105" />
            <div className="flex items-center justify-center">
                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"><a href={Demo}>Demo</a></button>
                <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105"><a href={Code}>Code</a></button>
            </div>
        </div>
            ))}
        </div>
        </div>
    </div>
  )
}

export default Portfolio